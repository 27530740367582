import React, { useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';


const Login = () => {

    const { oktaAuth } = useOktaAuth();
    const widgetRef = useRef();

    useEffect(() => {

        const CLIENT_ID = window.REACT_APP_OKTA_CLIENT_ID;
        //const ISSUER = window.REACT_APP_OKTA_BASE_URL + "/oauth2/default"; // 'https://dev-778286.okta.com/oauth2/default';
        const ISSUER = window.REACT_APP_OKTA_BASE_URL;
        const REDIRECTURI = window.location.origin + "/implicit/callback";
        const SCOPES = window.REACT_APP_OKTA_LOGIN_SCOPES.split(',');
        const OKTA_TESTING_DISABLEHTTPSCHECK = false;
        const DISABLE_OKTA_LOGIN_FORM = window.REACT_APP_DISABLE_OKTA_LOGIN_FORM ? window.REACT_APP_DISABLE_OKTA_LOGIN_FORM : "false";
        const IDP_OKTA_LOGIN_ID = window.REACT_APP_IDP_OKTA_LOGIN_ID;
        const IDP_OKTA_LOGIN_TEXT = window.REACT_APP_IDP_OKTA_LOGIN_TEXT;
        const HELP_LINK = window.REACT_APP_OKTA_HELP_LINK;

        const { pkce, issuer, clientId, redirectUri, scopes, azureIdText, helpLink} =
            {
                clientId: CLIENT_ID,
                issuer: ISSUER,
                redirectUri: REDIRECTURI,
                scopes: SCOPES,
                pkce: true,
                disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
                azureIdText: DISABLE_OKTA_LOGIN_FORM === "true"? [{id:IDP_OKTA_LOGIN_ID,text:IDP_OKTA_LOGIN_TEXT}] : [],
                helpLink: HELP_LINK
            }
        ;

        const widget = new OktaSignIn({

            baseUrl: issuer.split('/oauth2')[0],
            clientId,
            redirectUri,

            logo: window.location.origin + '/images/authlogo.png', // Try changing "okta.com" to other domains, like: "workday.com", "splunk.com", or "delmonte.com"
            language: 'en', // Try: [fr, de, es, ja, zh-CN] Full list: https://github.com/okta/okta-signin-widget#language-and-text
            helpLinks: {
                help: helpLink,
            },
            i18n: {
                //Overrides default text when using English. Override other languages by adding additional sections.
                'en': {
                    'primaryauth.title': window.REACT_APP_APP_TITLE + ' Login',   // Changes the sign in text
                    'primaryauth.submit': 'Login',  // Changes the sign in button
                    // More e.g. [primaryauth.username.placeholder,  primaryauth.password.placeholder, needhelp, etc.].
                    // Full list here: https://github.com/okta/okta-signin-widget/blob/master/packages/@okta/i18n/dist/properties/login.properties
                },
            },
            idps: azureIdText,
            idpDisplay: 'PRIMARY',
            authParams: {
                pkce,
                issuer,
                display: 'page',
                responseMode: pkce ? 'query' : 'fragment',
                scopes,
            },
        });

        widget.on('afterRender', (context) => {
            if(DISABLE_OKTA_LOGIN_FORM === "true"){
                const loginForm = document.querySelector('#form18');
                loginForm.style.visibility = "hidden";
                const divider = document.querySelector('.auth-divider');
                divider.style.visibility = "hidden";
                const footer = document.querySelector('.auth-footer');
                footer.style.visibility = "hidden";
            }
        });

        widget.renderEl(
            { el: widgetRef.current },
            (res) => {
                oktaAuth.handleLoginRedirect(res.tokens);
            },
            (err) => {
                throw err;
            },
        );

        return () => widget.remove();
    }, [oktaAuth]);

    return (
        <div>
            <div ref={widgetRef} />
        </div>
    );

};
export default Login;
