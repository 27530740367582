import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import {Grid} from "@material-ui/core";
import Link from '@material-ui/core/Link';
import MainMenu from "../menus/MainMenu";
import Drawer from "@material-ui/core/Drawer/Drawer";
import {withSnackbar} from "notistack";

const styles = theme => ({

    root: {},
    grow: {
        flexGrow: 1,  //needed for right alignment of logout button
    },
    toolbar: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    searchField: {
        position: 'absolute',
        left: '50%',
        //right: '75px',
        //right: theme.searchFieldPosition,
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    folderDetails: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    folderContent: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    folderSubfolders: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: '0px',
        paddingBottom: theme.spacing(1),
        overflowY: "auto"
    },
    appBar: {
        marginBottom: theme.spacing(1)
    },
    appBarLogo: {
        paddingTop: (theme.appBarLogo ? theme.appBarLogo.paddingTop : "0px")
    },
    //search progress message
    searching: {
        color: '#808080',
        fontSize: '0.75rem',
        fontWeight: '500',
        lineHeight: '24px',
        paddingLeft: theme.spacing(1)
    },
    searchProgressBar: {
        marginTop: -theme.spacing(1)
    },
    contentShift: {
        width: `calc(100% - ${theme.drawerWidth}px)`,
        marginLeft: theme.drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 0,
        marginRight: 0,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: theme.drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: theme.drawerWidth,
        borderRightColor: theme.palette.secondary.main
    },
    drawerHeader: {
        paddingTop: theme.spacing(2),
        paddingRight: '0px',
        paddingLeft: theme.spacing(2),
        ...theme.mixins.toolbar,
        justifyContent: 'left',
        borderTop: 'solid',
        borderTopColor: theme.palette.secondary.main,
    },

    menuListItemHeader: {
        color: theme.menuListItem.headerColour
    },
    menuListItem: {
        color: theme.palette.primary.main
    },

    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -theme.drawerWidth,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    linkButton :{

    }

});

function PortalApp(props) {

    const configFiles = props.configFiles;
    const debug = window.location.pathname.toLowerCase().includes("debug");

    if (debug)  {
        console.log ('PortalApp props=', props);
        console.log ("configFiles.actionsConfig:", configFiles.actionsConfig);
        console.log ("REACT_APP_APP_BAR_LOGO_LINK_URL=", window.REACT_APP_APP_BAR_LOGO_LINK_URL);
        console.log ("REACT_APP_APP_BAR_LOGO_SHOW=", window.REACT_APP_APP_BAR_LOGO_SHOW);
        console.log ("REACT_APP_APP_TITLE=", window.REACT_APP_APP_TITLE);
        console.log ("REACT_APP_SECURITY_API_BASE_URL=", window.REACT_APP_SECURITY_API_BASE_URL);
        console.log ("REACT_APP_SECURITY_API_USER_ENDPOINT=", window.REACT_APP_SECURITY_API_USER_ENDPOINT);
        console.log ("REACT_APP_OKTA_BASE_URL=", window.REACT_APP_OKTA_BASE_URL);
        console.log ("REACT_APP_OKTA_CLIENT_ID=", window.REACT_APP_OKTA_CLIENT_ID);
        console.log ("REACT_APP_OKTA_LOGIN_SCOPES=",window.REACT_APP_OKTA_LOGIN_SCOPES);
        console.log ("REACT_APP_OKTA_GROUP_ATTRIBUTE", window.REACT_APP_OKTA_GROUP_ATTRIBUTE);
        console.log ("REACT_APP_OPEN_MENU_DRAWER_ON_LAUNCH=", window.REACT_APP_OPEN_MENU_DRAWER_ON_LAUNCH);
        console.log ("REACT_APP_CONFIG_API_REQUIRED=", window.REACT_APP_CONFIG_API_REQUIRED);
        console.log ("REACT_APP_CONFIG_API_BASE_URL", window.REACT_APP_CONFIG_API_BASE_URL);
    }

    const [drawerOpen, setDrawerOpen] = useState(window.REACT_APP_OPEN_MENU_DRAWER_ON_LAUNCH === "true" || window.REACT_APP_OPEN_MENU_DRAWER_ON_LAUNCH === true)

    const handleDrawerOpen = () => {
        setDrawerOpen(true)
    };

    const manualDrawerClose = () => {
        setDrawerOpen(false)
    };

    const { classes, theme, ...other } = props;

    return (
        <React.Fragment>
            <AppBar
                position={"sticky"}
                color="secondary"
                className={classNames(classes.appBar, {[classes.contentShift]: drawerOpen })}>
                <Toolbar disableGutters={false} className = {classNames(classes.grow, classes.toolbar)}>
                    <Tooltip title={"Menu"}>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={handleDrawerOpen}
                            className={classNames(classes.menuButton, drawerOpen && classes.hide)}>
                            <i className='material-icons'>menu</i>
                        </IconButton>
                    </Tooltip>

                    <Typography variant="h6" color="inherit" className={classes.grow}>
                        {window.REACT_APP_APP_TITLE}
                    </Typography>

                    <Typography variant="h6" color="inherit">
                        {
                            (window.REACT_APP_APP_BAR_LOGO_SHOW === "true" || window.REACT_APP_APP_BAR_LOGO_SHOW === true) &&
                            <div className={classes.appBarLogo}>
                                {
                                    window.REACT_APP_APP_BAR_LOGO_LINK_URL && window.REACT_APP_APP_BAR_LOGO_LINK_URL !== "" ?
                                        <Tooltip title = {window.REACT_APP_APP_BAR_LOGO_LINK_URL}>
                                            <Link href={window.REACT_APP_APP_BAR_LOGO_LINK_URL}
                                                  target="_blank">
                                                <img src={window.location.origin + '/images/logo_appbar.png'} alt="logo" height={theme.appBarLogo.height}/>
                                            </Link>
                                        </Tooltip> :
                                        <img src={window.location.origin + '/images/logo_appbar.png'} alt="logo" height={theme.appBarLogo.height}/>
                                }
                            </div>
                        }
                    </Typography>

                </Toolbar>
            </AppBar>

            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={drawerOpen}
                classes={{paper: classes.drawerPaper,}}
            >
                <MainMenu
                    {...other}
                    drawerOpen={drawerOpen}
                    logout={props.logout}
                    userDetails={props.userDetails}
                    actionsConfig={configFiles.actionsConfig}
                    manualDrawerClose={manualDrawerClose}

                />
            </Drawer>

            <div className={classNames({[classes.contentShift]: drawerOpen,})}>
                <Grid container spacing={0} style={{overflowY:'auto'}}>
                    <Grid item xs={12}>

                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
}


PortalApp.propTypes = {
    classes: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    configFiles: PropTypes.object.isRequired,
    drawerWidth: PropTypes.number.isRequired
};

export default withSnackbar(withStyles(styles, { withTheme: true })(PortalApp));
