import React, {useState} from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {withSnackbar} from "notistack";
import {withStyles} from '@material-ui/core/styles';
import LinearProgress from "@material-ui/core/LinearProgress";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {Grid} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from '@material-ui/core/Checkbox';

const styles = (theme) => ({
    disabled: {}, //required for the rule below to work
    root: {
        '&$disabled': {
            color: theme.disabledField.colour
        },
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    customLabel:{
        color: theme.fieldLabel.colour,
        position: 'relative',
        marginTop: '0px'
    },
    list: {
        width: '100%',
        height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
    },

    listItem: {
        minWidth: 0,
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
    },
    listContainer: {
        overflow: 'auto',
    },
    listItemTextTransfer: {
        fontSize: theme.typography.pxToRem(16),
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
        //fontSize: '30px',
    },
    button: {
        margin: theme.spacing(0.5, 0),
        width: '100%',
        minWidth: 32
    },

    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

    rootRadio: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    iconRadio: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIconRadio: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

});

function AddDocTypeDialog(props) {

    const debug = window.location.pathname.toLowerCase().includes("debug");
    debug && console.log ('EditUserDialog props = ', props);

    const [retrieveSuccess] = useState(false);
    const [isRetrieving] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [success] = useState(false);
    const [isSaving] = useState(false);
    const {classes} = props;

    function handleAddClick() {
            add()
    }

    async function add() {

        setIsProcessing(true)
    }


    function handleCloseDialog() {
        props.handleCloseDialog();
    }

    return (
        <div>
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth= {retrieveSuccess ? "md" : "sm"}
                keepMounted={false}
            >
                <DialogTitle>Add Document Type</DialogTitle>

                <React.Fragment>
                <Grid container spacing={12}>
                    <Grid item xs={12}>

                        <FormControl component="fieldset">
                            <InputLabel shrink htmlFor="category" className={classes.customLabel}>Category</InputLabel>
                            <RadioGroup
                                aria-label="gender"
                                name="controlled-radio-buttons-group"
                                value={false}
                                onChange={false}
                            >
                                <FormControlLabel value="service" control={<Radio />} label="Service" />
                                <FormControlLabel value="legislative" control={<Radio />} label="Legislative" />
                            </RadioGroup>
                        </FormControl>

                    </Grid>
                </Grid>

                <Grid container spacing={12}>
                    <Grid item xs={12}>

                <FormControl component="fieldset">
                    <InputLabel shrink htmlFor="business-unit" className={classes.customLabel}>Business Unit</InputLabel>
                        <FormControlLabel control={<Checkbox/>} label="DGTT" />
                        <FormControlLabel control={<Checkbox/>} label="DGBC" />
                        <FormControlLabel control={<Checkbox/>} label="DGV" />
                        <FormControlLabel control={<Checkbox/>} label="DGS" />
                </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={12}>
                    <Grid item xs={12}>

                        <FormControl component="fieldset">
                            <InputLabel shrink htmlFor="compliance-pack" className={classes.customLabel}>Compliance Pack</InputLabel>
                            <RadioGroup row aria-label="compliance-pack" name="row-radio-buttons-group">
                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={12}>
                    <Grid item xs={12}>

                        <FormControl component="fieldset">
                            <InputLabel shrink htmlFor="visible-to-customers" className={classes.customLabel}>Visible to Customers</InputLabel>
                            <RadioGroup row aria-label="visible-to-customers" name="row-radio-buttons-group">
                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={12}>
                    <Grid item xs={12}>

                        <FormControl component="fieldset">
                            <InputLabel shrink htmlFor="archived" className={classes.customLabel}>Archived</InputLabel>
                            <RadioGroup row aria-label="archived" name="row-radio-buttons-group" >
                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                <FormControlLabel value="no" control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                </React.Fragment>

                <DialogActions>
                    {
                        !success &&
                        <Button onClick={() => handleAddClick()} variant={"contained"} color="secondary">
                            {isProcessing ? "Processing..." : "Add"}
                        </Button>
                    }
                    {
                        !isRetrieving && !isSaving &&

                        <Button onClick={() => handleCloseDialog()} variant="contained" >
                            { retrieveSuccess ? "Close"  : "Cancel"}
                        </Button>
                    }

                </DialogActions>
                {
                    (isRetrieving || isSaving) &&
                    <LinearProgress variant={"contained"} color={"primary"}/>
                }

            </Dialog>
        </div>
    );
}

AddDocTypeDialog.propTypes = {
    actionConfig: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles, { withTheme: true })(AddDocTypeDialog));