import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Grid} from "@material-ui/core";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar/Avatar";
import LogoutMenuItem from "../auth/LogoutMenuItem";
import {getColour, getInitials} from "../common/helper"
import CardMedia from '@material-ui/core/CardMedia'
import AdminMenu from "./AdminMenu";

const styles = (theme) => ({
    list: {
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0),
    },

    listItemLevel1: {
        paddingBottom: "2px",
        paddingTop: "2px",
    },

    listIcon: {
        minWidth: theme.spacing(4),
        color: theme.menuListItem.colour
    },

    listAvatar: {
        //width: theme.spacing(3),
        //height: theme.spacing(3),
    },

    listItemText: {
        color: theme.menuListItem.colour
    },

    topLevelText: {
        color: theme.menuListItem.colour
    },

    listIconNested: {
        minWidth: theme.spacing(4),
        color: theme.menuListItem.colour
    },

    nestedLevel1: {
        paddingLeft: theme.spacing(6),
        paddingTop: theme.spacing(.5),
        paddingBottom: theme.spacing(0),
        color: theme.menuListItem.colour
    }

});

function MainMenu(props) {

    const { classes, ...other  } = props;

    return (

        <Grid container spacing={0} alignItems={'flex-start'} style={{paddingTop: '10px'}}>
            <Grid item xs={10}
                  style={{paddingLeft: '15px'}}>
                <CardMedia
                    component="img"
                    image={window.location.origin + '/images/logo.png'}
                    title="Logo"
                />

            </Grid>
            <Grid item xs={2}>
                <IconButton onClick={props.manualDrawerClose} color={'secondary'}>
                    <i className='material-icons'>chevron_left</i>
                </IconButton>
            </Grid>
            <Grid item xs={12} style={{height: "100%", overflowY: "auto"}}>
                <List className={classes.list}>

                    <AdminMenu
                        parentClasses={classes}
                        actionsConfig={props.actionsConfig}
                        triggerRefreshAuthToken={props.triggerRefreshAuthToken}
                        userDetails={props.userDetails}
                    />

                    <ListItem
                        button={false}
                        key={'profile'}
                        id={'profile'}>

                        <ListItemAvatar>
                            <Avatar className={classes.listAvatar} style={{backgroundColor: getColour(props.userDetails.userName)}}>
                                {getInitials(props.userDetails.userName)}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={props.userDetails.userName}
                                      secondary={<span className={classes.menuListItem}>{props.userDetails.userEmail}</span>}
                        />
                    </ListItem>

                    <LogoutMenuItem {...other}
                                    logout={props.logout}
                                    userDetails={props.userDetails}/>
                </List>
            </Grid>
        </Grid>

    );

}

MainMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    manualDrawerClose: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    actionsConfig: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(MainMenu);