import React from 'react';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import {Security, SecureRoute, LoginCallback} from '@okta/okta-react';
import { OktaAuth,toRelativeUrl } from '@okta/okta-auth-js';
import Home from './Home';
import CustomLoginComponent from '../auth/LoginPage';

const HasAccessToRouter = () => {
    const history = useHistory(); // example from react-router

    const customAuthHandler = () => {
        // Redirect to the /login page that has a CustomLoginComponent
        history.push('/login');
    };


    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };


    const oidcConfig = {
        clientId: window.REACT_APP_OKTA_CLIENT_ID,
        //issuer: window.REACT_APP_OKTA_BASE_URL + "/oauth2/default",  //needed for iqeq?
        issuer: window.REACT_APP_OKTA_BASE_URL,
        redirectUri: window.location.origin + "/implicit/callback",
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: false,
        logoutUrl: window.location.origin
    }

    const oktaAuth = new OktaAuth(oidcConfig);

    return (
        <Security
            oktaAuth={oktaAuth}
            onAuthRequired={customAuthHandler}
            restoreOriginalUri={restoreOriginalUri}
        >
            <Route exact path="/" render={props => <Home {...props}/>} />
            <Route path="/implicit/callback" component={LoginCallback} />
            <Route path="/login" component={CustomLoginComponent} />

            <SecureRoute exact path="/debug" render={props => <Home {...props}/>} />

        </Security>

    );
};

const App = () => (
    <div>
        <Router>
            <HasAccessToRouter />
        </Router>
    </div>
);

export default App;