import { useOktaAuth } from '@okta/okta-react';
import React, { useState, useEffect } from 'react';
import './App.css';
import PortalApp from "./PortalApp";
import {SnackbarProvider} from 'notistack';
import 'typeface-roboto';
import 'typeface-montserrat';
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

let configFiles = {};

const Home = props => {

    const configFilePaths = {
        actionsConfig: 'config/configActions.json'
    };

    async function asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    async function getConfigFiles(configPrefix) {

        // application config - either retrieve from api or pull from local config
        if (window.REACT_APP_CONFIG_API_REQUIRED === "true" || window.REACT_APP_CONFIG_API_REQUIRED === true) {
            const url = window.REACT_APP_CONFIG_API_BASE_URL;

            window.location.pathname.toLowerCase().includes("debug") && console.log('get config files from service..url: ', url);

            const request = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + authState.accessToken.accessToken
                }
            };

            fetch(url, request)
                .then(response => response.json())
                .then(data => {
                    window.location.pathname.toLowerCase().includes("debug") && console.log('fetchConfigDetails - data...', data);
                    configFiles = {
                        actionsConfig: JSON.parse(data.action)
                    }
                })
                .catch(e => {
                    window.location.pathname.toLowerCase().includes("debug") && console.log(e);
                    setErrorDetails("Config Retrieval Error" + e);
                })
        } else {

            //initialise values in case new workflowConfig file not available
            configFiles = {
                actionsConfig: {}
            }

            window.location.pathname.toLowerCase().includes("debug") && console.log('getConfigFiles - start ');
            await asyncForEach(Object.entries(configFilePaths), async (entry) => {
                let url = configPrefix + entry[1];
                await fetch(url)
                    .then(response => {
                        return(response.json())
                    })
                    .then(data => {
                        window.location.pathname.toLowerCase().includes("debug") && console.log('getConfigFiles -', entry[1], '=', data)
                        configFiles[entry[0]] = data;
                    })
                    .catch(e => {
                        window.location.pathname.toLowerCase().includes("debug") && console.log("Get config files exception:" , e, " entry: ", entry);
                    })
            })
            window.location.pathname.toLowerCase().includes("debug") && console.log('getConfigFiles - Done: ', configFiles);
            return configFiles
        }
    }

    //theme imported via script tag in index.html
    let theme;
    // need to check for theme.default.... in case new config has not been picked up following deployment.
    if(window.theme.theme.default) {
        if(window.localStorage.getItem('theme') && window.localStorage.getItem('theme') !== "") {
            if(window.theme.theme[window.localStorage.getItem('theme')]){
                theme = window.theme.theme[window.localStorage.getItem('theme')];
            } else {
                theme = window.theme.theme.default;
            }
        } else {
            theme = window.theme.theme.default;
        }
    } else {
        theme = window.theme.theme;
    }

    window.location.pathname.toLowerCase().includes("debug") && console.log('theme=', theme);

    const portalTheme = createTheme({

        drawerWidth: theme.drawerWidth,

        appBarLogo: theme.appBarLogo ? theme.appBarLogo : {},

        menuListItem: theme.menuListItem ? theme.menuListItem : {},

        documentMenu: {
            color: theme.documentMenu.colour,
        },

        selectedTab: theme.selectedTab ? theme.selectedTab : {},

        disabledField: theme.disabledField ? theme.disabledField : {},

        fieldLabel: theme.fieldLabel ? theme.fieldLabel : {},

        typography: {
            suppressWarning: true,
            fontSize: 12,
            //fontFamily: theme.fontFamily
        },

        palette: theme.palette,

        //https://material-ui.com/customization/globals/#css
        overrides: {

            MuiCardMedia: {
                media: {
                    width: theme.logoWidth
                }
            },

            MuiBadge: {
                colorPrimary: {
                    backgroundColor: "#43a047"
                }
            },

            MuiListSubheader: {
                colorPrimary: {
                    color: theme.palette.secondary.main
                }
            },

            MuiAvatar: {
                colorDefault: {
                    backgroundColor: theme.palette.secondary.main
                }
            },

            MuiButton: {
                containedSecondary: {
                    backgroundColor: theme.palette.secondary.main
                }
            },

            MuiTab: {
                root: {
                    minWidth: "30px",
                    "&:hover": {
                        backgroundColor: "#eee",
                        color: theme.palette.primary.main
                    },
                    "&:active": {
                        backgroundColor: "#eee",
                        color: theme.palette.primary.main
                    },
                    "&:selected": {
                        backgroundColor: "#eee",
                        color: theme.palette.primary.main
                    }
                },

            },

            MuiTabs: {
                indicator: {
                    backgroundColor: theme.selectedTab.underlineColour
                },
            },

            //not taking effect in Upload dialog
            MuiInput: {
                formControl: {
                    "label + &": {
                        marginTop: "0px"
                    }
                }
            }
        }
    });

    const history = useHistory();

    const drawerWidth = theme.drawerWidth;
    const {oktaAuth } = useOktaAuth();
    const authState = oktaAuth.authStateManager.getAuthState() //NEW
    const [userDetails, setUserDetails] = useState(null);
    const [errorDetails, setErrorDetails] = useState(null);

    let urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('code')) {
        console.log ('url has code param so set redirectUrlCode and redirect to ' + window.localStorage.getItem('windowUrl'))
        console.log ('authState=', authState);
        window.localStorage.setItem('redirectUrlCode', window.location);
        window.open(window.localStorage.getItem('windowUrl'), "_self");
    } else if (urlParams.has('error')){
        window.localStorage.setItem('error',urlParams.get('error'));
    } else {
        window.localStorage.setItem('windowUrl', window.location);
    }

    useEffect(() => {

        //runs when authState/oktaAuth change
        const debug = window.location.pathname.toLowerCase().includes("debug");

        let boxPortalRole;

        if (!authState || !authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserDetails(null);
            setErrorDetails(null);
            debug && console.log('!authState.isAuthenticated');
        } else {
            // user is authenticated...
            if (window.localStorage.getItem('error') !== null && window.localStorage.getItem('error') !== "null") {
                setErrorDetails(window.localStorage.getItem('error'));
                window.localStorage.setItem('error', null);
            } else {
                window.location.pathname.toLowerCase().includes("debug") && console.log('authState.isAuthenticated... process token...');
                oktaAuth.getUser().then((info) => {
                    debug && console.log('oktaAuth.getUser response info = ', info);

                    // dynamic theme....
                    if(info.portalTheme){
                        window.localStorage.setItem('theme',info.portalTheme);
                    } else {
                        window.localStorage.setItem('theme',"");
                    }

                    const configPrefix = "../../";

                    getConfigFiles(configPrefix).then(configFiles => {

                        const accessToken = authState.accessToken.accessToken;
                        boxPortalRole = info[window.REACT_APP_OKTA_GROUP_ATTRIBUTE];

                        const date = new Date();
                        const currentTime = date.getTime();
                        const expiresIn = currentTime + (1000 * 60 * parseInt(window.REACT_APP_OKTA_REFRESH));

                        setUserDetails({
                            isUserLoggedIn: true,
                            userLoggedOut: false,
                            userName: info.name,
                            userEmail: info.email,
                            expiresIn: expiresIn,
                            boxPortalRole: boxPortalRole,
                            accessToken: accessToken,
                            otherInfo: "...."
                        })

                    })

                });
            }
        }

    }, [authState, oktaAuth]); // runs if authState/oktaAuth change

    const login = async () => {

        history.push('/login');
    };

    const logout = async () => {

        window.localStorage.setItem('redirectUrlCode', null);
        window.localStorage.setItem('state', null);
        window.localStorage.setItem('nonce', null);
        window.localStorage.setItem('windowUrl', null);

        await oktaAuth.signOut()

    }

    const refreshAuthToken = async () => {

        const date = new Date();
        const currentTime = date.getTime();

        if(userDetails.expiresIn < currentTime) {
            window.location.pathname.toLowerCase().includes("debug") && console.log('userDetails.expiresIn < currentTime, refresh access token')
            const date = new Date();
            const currentTime = date.getTime();
            const expiresIn = currentTime + (1000 * 60 * parseInt(window.REACT_APP_OKTA_REFRESH));

            setUserDetails({
                accessToken: authState.accessToken.accessToken,
                expiresIn: expiresIn,
                isUserLoggedIn: userDetails.isUserLoggedIn,
                userLoggedOut: userDetails.userLoggedOut,
                userName: userDetails.userName,
                userEmail: userDetails.userEmail,
                boxPortalRole: userDetails.boxPortalRole,
                otherInfo: userDetails.otherInfo
            })
        }
    };

    // if (authState.isPending) {
    //     window.location.pathname.toLowerCase().includes("debug") && console.log ('authState.isPending');
    //     return (
    //         <React.Fragment>
    //             <LinearProgress style={{marginTop: -8, marginLeft: -8}} color="primary"/>
    //         </React.Fragment>
    //     );
    // }

    if( !authState ) {
        return <div>Loading...</div>;
    }

    if(!authState.isAuthenticated  ){
        // gets here after logout
        login();
    }

    return (
        <ThemeProvider theme={portalTheme}>
            <div>
                <SnackbarProvider maxSnack={1}>
                    {
                        authState.isAuthenticated && userDetails ?
                            <PortalApp {...props}
                                       userDetails={userDetails}
                                       logout = {logout}
                                       triggerRefreshAuthToken = {refreshAuthToken}
                                       configFiles={configFiles}
                                       drawerWidth={drawerWidth}
                            /> :

                            errorDetails === null ?
                                <React.Fragment>
                                    <LinearProgress color="secondary"/>
                                    <div style={{color: '#808080', fontSize: '0.75rem', fontWeight: '500', lineHeight: '24px', paddingLeft: '6px'}}>
                                        Loading user information...</div>
                                </React.Fragment> :

                                <div style={{
                                    textAlign: "center",
                                    borderColor: '#ddd #ddd #d8d8d8',
                                    boxShadow: 'box-shadow: 0 2px 0 hsla(0,0%,68.6%,.12',
                                    width: "50%", height: "50%",
                                    overflow: 'visible',
                                    margin: 'auto',
                                    position: 'absolute',
                                    top: '0', left: '0', bottom: '0', right: '0'
                                }}>
                                    <img src={window.location.origin + '/images/authlogo.png'} alt="logo"
                                         style={{display: "block", margin: "0 auto", maxWidth: "200px", maxHeight: "40px"}}/><br/><br/>
                                    <Typography variant="h6">{window.REACT_APP_APP_TITLE}</Typography><br/><br/>
                                    <Typography variant="subtitle1">An error has occurred, please retry.</Typography><br/><br/>
                                    <Typography variant="subtitle2">{errorDetails}</Typography><br/><br/>
                                    <Button variant="contained" color="secondary" onClick={logout} style={{width: "200px", marginTop: "20px"}}>Return to login</Button>
                                </div>


                    }
                </SnackbarProvider>
            </div>
        </ThemeProvider>
    );
};
export default Home;