import React, {useEffect, useReducer, useState} from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {withSnackbar} from "notistack";
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Grid} from "@material-ui/core";
import {getOktaUsers, validateEmail} from "../../common/helper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input/Input";

const styles = (theme) => ({
    disabled: {}, //required for the rule below to work
    root: {
        '&$disabled': {
            color: theme.disabledField.colour
        },
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    customLabel:{
        color: theme.fieldLabel.colour,
        position: 'relative',
        marginTop: '0px'
    },
    list: {
        width: '100%',
        height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
    },

    listItem: {
        minWidth: 0,
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
    },
    listContainer: {
        overflow: 'auto',
    },
    listItemTextTransfer: {
        fontSize: theme.typography.pxToRem(16),
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0)
        //fontSize: '30px',
    },
    button: {
        margin: theme.spacing(0.5, 0),
        width: '100%',
        minWidth: 32
    },

    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

    rootRadio: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    iconRadio: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIconRadio: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

});

function AssignUserDialog(props) {

    const debug = window.location.pathname.toLowerCase().includes("debug");
    //debug && console.log ('AssignUserDialog props = ', props);

    const [assignSuccess,] = useState(false);
    const [isAssigning, setIsAssigning] = useState(false);
    const [userName, setUserName] = useState({value:""})
    const [updatingUsers, setUpdatingUsers] = useState(false);

    let initialState =  {
        userOptions: [],
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {

        async function initUserOptions() {

            setUpdatingUsers(true);

            let options = await getOktaUsers(props.userDetails, props.triggerRefreshAuthToken)

            if (options.length === 0) {
                props.enqueueSnackbar("No users found", {variant: 'error'});
            }

            return (
                dispatch({type: "USER OPTIONS", value: options})
            )
        }

        if (props.actionConfig.useAutoComplete) {
            initUserOptions()
        }

    },[] )


    function reducer(state, action) {

        switch (action.type) {

            case "USER OPTIONS": {
                setUpdatingUsers(false);
                return {
                    ...state,
                    userOptions: action.value,
                    //initUserOptionsDone: true
                }
            }

            default:
                return state
        }
    }

    function handleCloseDialog() {
        props.handleCloseDialog();
    }


    function handleAssignClick() {

        if (validateEmail(userName.value)) {
            assign()
        } else {
            props.enqueueSnackbar("Please enter a valid email address" , {variant: 'error'});
        }
    }

    async function assign() {

        setIsAssigning(true)

        const url = window.REACT_APP_SECURITY_API_BASE_URL + "/okta/user/assign";

        const body = {
            "username": userName.value
        }

        const request = {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + props.userDetails.accessToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }

        debug && console.log('assignUser url=', url, 'request=', request);

        await props.triggerRefreshAuthToken();
        let error = false;
        let errorMessage = "";
        let errorCode;

        await fetch(url, request)
            .then(response => {
                debug && console.log('assignUser response=', response);
                error = !response.ok
                if (error){
                    errorMessage = response.statusText
                    errorCode = response.status
                    return null
                } else {
                    return response.json();
                }
            })
            .then(data => {
                debug && console.log('editUser response.json = ', data)

                if (error || !data) {
                    errorMessage = data && data.message ? data.message : errorMessage
                    props.enqueueSnackbar("Error " + errorCode + " assigning user (" + errorMessage + ")", {variant: 'error'});
                } else {
                    props.enqueueSnackbar("User assigned successfully", {variant: 'success'});
                    handleCloseDialog();
                }
                setIsAssigning(false)

            })
            .catch(e => {
                props.enqueueSnackbar("Error assigning user details (exception: " + e.message + ")" , {variant: 'error'});
                debug && console.log("editUser  exception:" , e);
                setIsAssigning(false)
            })
    }


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (!assignSuccess && !isAssigning) {
                if ( userName.length > 0) {
                    handleAssignClick()
                }
            }
        }
    }

    const {classes} = props;

    const defaultProps = {
        options: state.userOptions,
        getOptionLabel: (option) => option.label || ""
    };

    return (
        <div>
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth= {assignSuccess ? "md" : "sm"}
                keepMounted={false}
            >
                <DialogTitle>Assign User</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {
                                updatingUsers ?

                                    <LinearProgress variant={"indeterminate"} color={"secondary"}/> :

                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="portalUser"
                                                    className={classes.customLabel}>Username</InputLabel>

                                        {
                                            props.actionConfig.useAutoComplete ?
                                                <Autocomplete
                                                    {...defaultProps}
                                                    id={"portalUser"}
                                                    name={"portalUser"}
                                                    debug
                                                    value={userName}
                                                    autoFocus={true}
                                                    onChange={(event, newValue) => {
                                                        setUserName(newValue);
                                                    }}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                                   margin="none"
                                                                   autoComplete={"off"}
                                                                   disabled={isAssigning}
                                                                   onKeyDown={handleKeyDown}
                                                                   helperText={"Email address that this user registered with"}
                                                        />
                                                    }
                                                /> :

                                                <Input id="portalUser"
                                                       name="portalUser"
                                                       value={userName.value}
                                                       placeholder="Enter user logon (email address)"
                                                       type="text"
                                                       margin="none"
                                                       variant="outlined"
                                                       autoComplete=""
                                                       onChange={e => setUserName({value: e.target.value})}
                                                       autoFocus={true}
                                                       disabled={isAssigning}
                                                />
                                        }

                                    </FormControl>
                            }
                        </Grid>
                    </Grid>


                </DialogContent>

                <DialogActions>
                    {
                        !isAssigning &&

                        <Button onClick={() => handleCloseDialog()} variant="contained" >
                            { assignSuccess ? "Close"  : "Cancel"}
                        </Button>
                    }

                    <Button color="secondary" onClick={() => handleAssignClick()} variant={"contained"} disabled={userName.length === 0 || isAssigning}>
                        {isAssigning ? "Assigning..." : "Assign user"}
                    </Button>


                </DialogActions>
                {
                    isAssigning &&
                    <LinearProgress variant={"contained"} color={"primary"}/>
                }

            </Dialog>
        </div>
    );
}


AssignUserDialog.propTypes = {
    actionConfig: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired
};


export default withSnackbar(withStyles(styles, { withTheme: true })(AssignUserDialog));