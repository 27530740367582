import React, {useEffect, useReducer, useState} from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import {withSnackbar} from "notistack";
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Grid} from "@material-ui/core";
import Input from "@material-ui/core/Input/Input";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {getOktaGroups, getObjectByKey, validateEmail, getErrorMessageFromResponse} from "../../common/helper";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import {green} from "@material-ui/core/colors";
import classNames from 'classnames';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = (theme) => ({
    disabled: {}, //required for the rule below to work
    root: {
        '&$disabled': {
            color: theme.disabledField.colour
        },
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    customLabel:{
        color: theme.fieldLabel.colour,
        position: 'relative',
        marginTop: '0px'
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
    rootRadio: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    iconRadio: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIconRadio: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },

});


function AddUserDialog(props) {

    const debug = window.location.pathname.toLowerCase().includes("debug");
    //debug && console.log ('AddUserDialog props = ', props);

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [success, setSuccess] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const actionConfig = props.actionConfig;
    const userTypeOptions = actionConfig && actionConfig.userType && actionConfig.userType.options ;
    const [userType, setUserType] = useState(actionConfig && actionConfig.userType && actionConfig.userType.defaultValue ? actionConfig.userType.defaultValue : "" );
    const [updatingGroups, setUpdatingGroups] = useState(false);
    const fixedGroupOptions = [];
    const [groups, setGroups] = useState(fixedGroupOptions)

    const handleChangeUserType = (event) => {

        setUserType(event.target.value);

        //useEffect now doing this as passing userType as a dependency
        // getGroupOptions(event.target.value).then(response => {
        //     setUpdatingGroups(false);
        //     //reset group selection
        //     setGroups(fixedGroupOptions);
        // })

    };


    let initialState =  {
        groupOptions: [],
        getGroupOptionsDone: false
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {

        async function getGroupOptions (userType) {

            const debug = window.location.pathname.toLowerCase().includes("debug");
            debug && console.log('AddUserDialog getGroupOptions');

            setUpdatingGroups(true);

            //get regex filter from config
            debug && console.log('userType=', userType);
            debug && console.log('userTypeOptions=', userTypeOptions);

            const selectedUserTypeOption = userTypeOptions && userTypeOptions.length > 1 ? getObjectByKey("value", userType, userTypeOptions) : undefined;
            debug && console.log('selectedUserTypeOotion=', selectedUserTypeOption);
            const regexFilter = (selectedUserTypeOption && selectedUserTypeOption.regexFilter) ? selectedUserTypeOption.regexFilter : "";
            const showGroupDesc = props.actionConfig.showGroupDesc;

            let options = await getOktaGroups(props.userDetails, props.triggerRefreshAuthToken, regexFilter, showGroupDesc);
            if (options.length === 0) {
                props.enqueueSnackbar("No groups found" , {variant: 'error'});
                debug && console.log ('No options found');
            }

            return (
                dispatch({type: "GROUP OPTIONS", value: options})
            )
        }

        getGroupOptions(userType)

    }, [userType, userTypeOptions])
    // [userType, props, userTypeOptions])

    function reducer(state, action) {

        switch (action.type) {

            case "GROUP OPTIONS": {
                setUpdatingGroups(false);
                //reset group selection
                setGroups(fixedGroupOptions);
                return {
                    ...state,
                    groupOptions: action.value,
                    //initGroupOptionsDone: true
                }
            }

            default:
                return state
        }
    }

    function handleCloseDialog() {
        props.handleCloseDialog();
    }

    const allFieldsComplete = email && firstName && lastName && groups.length > 0;

    function validateGroups() {

        //check at least one group meets the regular expression specified in the config
        let valid = true;

        if (actionConfig.groupValidation && actionConfig.groupValidation.regex) {
            const re = new RegExp(actionConfig.groupValidation.regex);
            for (let i = 0; i < groups.length; i++) {
                //Check that at least one group meets the regular expression specified in config
                valid = re.test(groups[i].label)
                if (valid) {
                    break;
                }
            }
            if (!valid) {
                props.enqueueSnackbar(actionConfig.groupValidation.message, {variant: 'error'});
            }
        }
        return valid
    }

    function validate() {

        let isValid = false;
        //check all fields complete and email format is valid

        if (allFieldsComplete) {
            if (validateEmail(email)) {
                isValid = true
                isValid = validateGroups()
            } else {
                props.enqueueSnackbar("Please enter a valid email address" , {variant: 'error'});
            }
        } else {
            props.enqueueSnackbar("Please complete all fields before submitting" , {variant: 'error'});
        }
        return isValid
    }

    function copyToClipboard() {
        window.navigator.clipboard.writeText(password).then(result=>{
            props.enqueueSnackbar("User password copied to clipboard", {variant: 'success'})
        })
    }

    function Summary() {

        const groupList = [];
        groups.forEach((group) => {groupList.push(group.value)})
        debug && console.log ('Summary: firstName=', firstName, 'lastName=', lastName, 'email=', email, 'userType=', userType, 'groups=', groups, 'groupList=', groupList, 'groupList.toString()=', groupList.toString())
        return (
            <List>
                <React.Fragment key={"frag"}>
                    <ListItem key={"li" + lastName}>
                        <ListItemAvatar><Avatar style={{backgroundColor: green[500]}}><i className='material-icons'>done</i></Avatar></ListItemAvatar>
                        <ListItemText key={"liText" + firstName} primary={firstName + " " + lastName + " (" + email + ") successfully added"} secondary={userType + " - " + groupList.toString()}/>
                    </ListItem>
                </React.Fragment>
            </List>

        )
    }

    function handleSubmitClick() {
        if (validate()) {
            submit()
        }
    }

    async function submit() {

        setIsProcessing(true)

        const groupList = [];
        groups.forEach((group) => {groupList.push(group.value)})

        const url = window.REACT_APP_SECURITY_API_BASE_URL + window.REACT_APP_SECURITY_API_USER_ENDPOINT;
        const body = {
            "username": email,
            "email": email,
            "firstName": firstName,
            "lastName": lastName,
            "groups": groupList
        }

        const request = {
            method: 'POST',
            headers: {
                "Authorization": "Bearer " + props.userDetails.accessToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        }

        debug && console.log('addUser url=', url, 'BODY', body, 'request=', request);

        await props.triggerRefreshAuthToken();

        await fetch(url, request)
            .then(response => {
                debug && console.log('addUser response=', response);
                if (response.ok) {
                    return(response.json())
                } else {
                    Promise.resolve(getErrorMessageFromResponse(response, 'adding user'))
                        .then(message => {
                            props.enqueueSnackbar(message, {variant: 'error'});
                        })
                    debug && console.log("add user error.  url:", url, "request: ", request);
                    return null
                }
            })
            .then(data => {
                debug && console.log('addUser response.json = ', data)

                if (data) {
                    setPassword(data.password)
                    setSuccess(true)
                    props.enqueueSnackbar("User added successfully" , {variant: 'success'});
                }

                setIsProcessing(false)

            })
            .catch(e => {
                props.enqueueSnackbar("Error adding user (exception: " + e.message + ")" , {variant: 'error'});
                debug && console.log("addUser  exception:" , e);
                setIsProcessing(false)
            })
    }

    const {classes} = props;

    return (
        <div>
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth= {"md"}
                keepMounted={false}
            >
                <DialogTitle>Add User</DialogTitle>
                <DialogContent>

                    <Grid container spacing={2}>

                        {
                            !success ?

                                <React.Fragment>
                                    <Grid item  xs={6}>
                                        <FormControl fullWidth >
                                            <InputLabel shrink htmlFor="email" className={classes.customLabel}>Email</InputLabel>
                                            <Input id="email"
                                                   name="email"
                                                   value={email}
                                                   placeholder="Enter user email"
                                                   type="text"
                                                   margin="none"
                                                   variant="outlined"
                                                   autoComplete=""
                                                   onChange={e => setEmail(e.target.value)}
                                                   autoFocus = {true}
                                                   disabled={isProcessing || success}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item  xs={6}>
                                        {
                                            (userTypeOptions && userTypeOptions.length > 0) &&

                                            <FormControl fullWidh component="fieldset">
                                                <InputLabel shrink htmlFor="userType" className={classes.customLabel}>User Type</InputLabel>
                                                <RadioGroup
                                                    row aria-label="userType" name="userType" value={userType} onChange={handleChangeUserType}

                                                    className={classes.rootRadio}
                                                    disableRipple
                                                    color="default"
                                                    checkedIcon={<span className={classNames(classes.iconRadio, classes.checkedIconRadio)} />}
                                                    icon={<span className={classes.icon} />}
                                                    {...props}
                                                >
                                                    {userTypeOptions.map(opt => {
                                                        return (
                                                            <FormControlLabel value= {opt.value} disabled={isProcessing || success} control={<Radio />} label={opt.label} />
                                                        )
                                                    })}
                                                </RadioGroup>
                                            </FormControl>
                                        }
                                    </Grid>
                                    <Grid item  xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="firstName" className={classes.customLabel}>First Name</InputLabel>
                                            <Input
                                                id="firstName"
                                                name="firstName"
                                                value={firstName}
                                                placeholder="Enter first name"
                                                type="text"
                                                margin="none"
                                                variant="outlined"
                                                autoComplete=""
                                                onChange={e => setFirstName(e.target.value)}
                                                autoFocus = {false}
                                                disabled={isProcessing || success}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item  xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="inputComment" className={classes.customLabel}>Last Name</InputLabel>
                                            <Input
                                                id="lastName"
                                                name="lastName"
                                                value={lastName}
                                                placeholder="Enter last name"
                                                type="text"
                                                margin="none"
                                                variant="outlined"
                                                autoComplete=""
                                                onChange={e => setLastName(e.target.value)}
                                                autoFocus = {false}
                                                disabled={isProcessing || success}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="groups-autocomplete" className={classes.customLabel}>Groups</InputLabel>
                                            {
                                                updatingGroups ?

                                                    <LinearProgress variant={"indeterminate"} color={"secondary"}/> :

                                                    <Autocomplete
                                                        //isOptionEqualToValue={(option, value) => option.value === value.value} //materialui v5 override isOptionEqualToValue to address console warning
                                                        getOptionSelected={(option, value) => option.value === value.value} //materialui v4 override getOptionSelected to address console warning
                                                        multiple
                                                        id="groups-autocomplete"
                                                        value={groups}
                                                        disabled={isProcessing || success}
                                                        onChange={(event, newValue) => {
                                                            setGroups([
                                                                ...fixedGroupOptions,
                                                                ...newValue.filter((option) => fixedGroupOptions.indexOf(option) === -1),
                                                            ]);
                                                        }}
                                                        options={updatingGroups ? [] : state.groupOptions}
                                                        getOptionLabel={(option) => option.label + (option.description ? ' (' + option.description + ')' : '')}
                                                        renderTags={(tagValue, getTagProps) =>
                                                            tagValue.map((option, index) => (
                                                                <Chip
                                                                    label={option.label + (option.description ? ' (' + option.description + ')' : '')}
                                                                    {...getTagProps({index})}
                                                                    disabled={isProcessing || success || fixedGroupOptions.indexOf(option) !== -1}
                                                                />
                                                            ))
                                                        }
                                                        style={{width: '100%'}}
                                                        renderInput={(params) => (
                                                            <TextField {...params} margin="none"/>
                                                        )}
                                                    />
                                            }
                                        </FormControl>
                                    </Grid>

                                </React.Fragment> :

                                <Grid item  xs={12}>

                                    <Summary/>

                                    <DialogContentText>Please copy the password and share it with the user, they will need to reset it on first use.<br/></DialogContentText>

                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="password" className={classes.customLabel}>Initial Password</InputLabel>
                                        <Input
                                            id = "password" name = "password" value = {password}
                                            type = "text" margin="none" variant="outlined"
                                            readOnly={true} style={{width: "100%"}}
                                            onFocus={event => {event.target.select()}}
                                        />
                                    </FormControl>

                                </Grid>

                        }
                    </Grid>

                </DialogContent>

                <DialogActions>
                    {
                        !isProcessing &&
                        <Button onClick={() => handleCloseDialog()} variant="contained" >
                            { success ? "Close"  : "Cancel"}
                        </Button>
                    }
                    {
                        !success &&
                        <Button onClick={() => handleSubmitClick()} variant={"contained"} color="secondary" disabled={!allFieldsComplete}>
                            {isProcessing ? "Processing..." : "Submit"}
                        </Button>
                    }
                    {
                        success && password &&
                        <Button onClick={() => copyToClipboard()} color="secondary" variant={"contained"}>Copy password</Button>
                    }

                </DialogActions>
                {
                    isProcessing &&
                    <LinearProgress variant={"indeterminate"} color={"primary"}/>
                }
            </Dialog>
        </div>
    );
}

AddUserDialog.propTypes = {
    actionConfig: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    triggerRefreshAuthToken: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired
};


export default withSnackbar(withStyles(styles, { withTheme: true })(AddUserDialog));