import React from 'react';
import PropTypes from 'prop-types';
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({

    drawerMenuListItem: {
        color: theme.menuListItem.colour
    },

    listIcon: {
        minWidth: theme.spacing(4),
        color: theme.menuListItem.colour
    },

});

function LogoutMenuItem(props) {

    const classes= props.classes;

    return (
        <ListItem
            button={true}
            key={'logout'}
            id={'logout'}
            onClick={props.logout}
        >
            <ListItemIcon className={classes.listIcon}><i className='material-icons'>lock_open</i></ListItemIcon>
            <ListItemText
                className={classes.drawerMenuListItem}
                primary={'Logout'}
            />
        </ListItem>
    );

}


LogoutMenuItem.propTypes = {
    classes: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(LogoutMenuItem);
