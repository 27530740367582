
export async function getOptionsExternalWrapper (optionsExternalConfig, userDetails, triggerRefreshAuthToken) {
    //wrapper to allow us to use use getOptions external from getOptions
    return await getOptionsExternal(optionsExternalConfig, userDetails, triggerRefreshAuthToken)

}

export async function getOptionsExternal(optionsExternalConfig, userDetails, triggerRefreshAuthToken) {

    const debug = window.location.pathname.toLowerCase().includes("debug");
    debug && console.log ("getOptionsExternal optionsExternalConfig = ", optionsExternalConfig)

    //e.g. get okta group members
    //window.location.pathname.toLowerCase().includes("debug") && console.log('optionsExternalConfig =', optionsExternalConfig);
    let options = [];

    if (optionsExternalConfig && optionsExternalConfig.source && optionsExternalConfig.source === "group") {
        options = await getOktaGroupMembers(optionsExternalConfig.group, userDetails, triggerRefreshAuthToken)
        return options
    } else {
        return options;
    }
}

export async function getOktaGroupMembers(group, userDetails, triggerRefreshAuthToken) {

    const debug = window.location.pathname.toLowerCase().includes("debug");

    //get okta group members

    const params =  "?name=" + group;
    const url = window.REACT_APP_SECURITY_API_BASE_URL + "/okta/group/members" + params;
    const request = {
        method: 'GET',
        headers: {"Authorization": "Bearer " + userDetails.accessToken},
    }

    debug && console.log('getOktaGroupMembers url=', url, 'request=', request);

    await triggerRefreshAuthToken();
    let error = false;
    let members = [];

    await fetch(url, request)
        .then(response => {
            debug && console.log('getOktaGroupMembers response=', response);
            error = !response.ok
            return response.json()
        })
        .then(data => {

            debug && console.log('getOktaGroupMembers response.json = ', data)

            if (error) {
                console.log ('*** ERROR ***', error, 'response.json = ' , data);
                members = [];
            } else {
                data.forEach(item => {
                    members.push({
                        label: item.login,
                        value: item.login,
                        user: item //add user object so we can get other properties if required, e.g. boxUserId
                    })
                });
            }

        })
        .catch(e => {
            debug && console.log("getOktaGroupMembers  exception:" , e);
        })

    debug && console.log('getOktaGroupMembers return members ', members);
    members.sort( dynamicSort("label") );
    debug && console.log('sorted members = ', members);
    return members;
}

export async function getOktaUsers( userDetails, triggerRefreshAuthToken) {

    //get all Okta users

    const debug = window.location.pathname.toLowerCase().includes("debug");

    const url = window.REACT_APP_SECURITY_API_BASE_URL + "/okta/users";
    const request = {
        method: 'GET',
        headers: {"Authorization": "Bearer " + userDetails.accessToken},
    }

    debug && console.log('getOktaUsers url=', url, 'request=', request);

    await triggerRefreshAuthToken();
    let error = false;
    let users = [];

    await fetch(url, request)
        .then(response => {
            debug && console.log('getOktaUsers response=', response);
            error = !response.ok
            return response.json()
        })
        .then(data => {

            debug && console.log('getOktaUsers response.json = ', data)

            if (error) {
                console.log ('ERROR', error, 'response.json = ' , data);
                users = [];
            } else {
                data.forEach(item => {
                    users.push({
                        label: item.login,
                        value: item.login,
                        user: item //add user object so we can get other properties if required, e.g. boxUserId
                    })
                });
            }

        })
        .catch(e => {
            debug && console.log("getOktaUsers  exception:" , e);
        })

    debug && console.log('getOktaUsers return users ', users);
    users.sort( dynamicSort("label") );
    debug && console.log('sorted users = ', users);
    return users;
}

export async function getOktaGroups(userDetails, triggerRefreshAuthToken, regexFilter, getDesc) {

    const debug = window.location.pathname.toLowerCase().includes("debug");

    //get okta groups
    const url = window.REACT_APP_SECURITY_API_BASE_URL + "/okta/group?regexFilter=" + encodeURIComponent(regexFilter) + (getDesc ? '&desc=true' : '');
    const request = {
        method: 'GET',
        headers: {"Authorization": "Bearer " + userDetails.accessToken},
    }

    debug && console.log('getOktaGroup url=', url, 'request=', request);

    await triggerRefreshAuthToken();
    let error = false;
    let groups = [];

    await fetch(url, request)
        .then(response => {
            debug && console.log('getOktaGroups response=', response);
            error = !response.ok
            return response.json()
        })
        .then(data => {

            debug && console.log('getOktaGroups response.json = ', data)

            if (error) {
                console.log ('*** ERROR ***', error, 'response.json = ' , data);
                groups = [];
            } else {
                data.forEach(item => {
                    //groups.push(item.name)
                    const group = {
                        label: item.name + (getDesc && item.description ? ' (' + item.description + ')' : ''),
                        value: item.name,
                        //id: item.id //for reference
                    }
                    // if (getDesc) {
                    //     group.description = item.description
                    // }
                    groups.push(group)
                });
            }

        })
        .catch(e => {
            debug && console.log("getOktaGroups  exception:" , e);
        })

    debug && console.log('getOktaGroups return groups ', groups);
    //groups.sort( dynamicSort("label") );
    //debug && console.log('sorted members = ', members);
    return groups;
}

export function hasAccess(actionConfig, userRoles) {
    //check if any of user's roles are listed in config
    let hasAccess = false;
    if (actionConfig && userRoles) {
        actionConfig.roles.forEach(role => {
                if (userRoles.includes(role)) {
                    hasAccess = true;
                }
            }
        )
    }
    return hasAccess;
}

export function validateEmail(email) {
    //check email is in the format 'anystring@anystring.anystring'
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export function getInitials(name) {

    let initials = "";

    if (name) {
        initials = name.match(/\b\w/g) || [];
        initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
    }

    return initials
}

export function getColour(name) {

    const saturation = 30;
    const lightness = 60;
    let hash = 0;

    if (name) {
        for (let i = 0; i < name.length; i++) {
            hash = name.charCodeAt(i) + ((hash << 5) - hash);
        }
    }

    let h = hash % 360;
    return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';

}

export function getErrorMessageFromResponse (response, requestDescription) {

    //const debug = window.location.pathname.toLowerCase().includes("debug");
    console.log ('getErrorMessageFromResponse response=', response, 'requestDescription=' , requestDescription)

    //requestDescription e.g. 'uploading file'
    let desc = requestDescription && requestDescription !== "" ?
        ("Error " + requestDescription + ": Error " + (response.status ? response.status : "")) :
        ((response.status ? response.status : ""));


    if (response.status === 401) {
        desc = desc + ".  Please log in again."
    }

    //return error details from response
    let errorMessage = "";
    const contentType = response.headers && response.headers.get("content-type");
    const hasJSON = contentType && contentType.indexOf("application/json") !== -1;
    const hasText = contentType && contentType.indexOf("text/plain") !== -1;

    if (hasJSON) {
        return (response.json()).then(responseJSON => {
            console.log('responseJSON = ', responseJSON);
            errorMessage = desc + (
                responseJSON.message ? (" " + responseJSON.message) :
                    responseJSON.error ? (" " + responseJSON.error) :
                        responseJSON.statusText ? (" " + responseJSON.statusText) : "");
            console.log('getErrorMessageFromResponse, response = ', response, 'return errorMessage: ', errorMessage);
            return (errorMessage)
        })
    } else if (hasText) {
        return(response.text()).then(responseText => {
            console.log ('responseText = ', responseText);
            errorMessage = desc + (
                responseText ? (" " + responseText) : "");
            console.log('getErrorMessageFromResponse, response = ', response, 'return errorMessage: ', errorMessage);
            return (errorMessage)
        })
    } else {
        //no json in response
        errorMessage = desc + (
            response.message ? " " + response.message :
                response.error ? " " + response.error :
                    response.statusText ? " " + response.statusText :
                        response.text() ? " " + response.text() :
                            "");

        return (errorMessage)
    }
}

export function getObjectByKey(nameKey, val, myArray){
    for (let i=0; i < myArray.length; i++) {
        if (myArray[i][nameKey] === val) {
            console.log ('getObjectByKey returning ', myArray[i])
            return myArray[i];
        }
    }
}

export function dynamicSort(property) {

    //Sorts an array of objectes by the property name specified in the property parameter

    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers*/
        var result = (a[property].toLowerCase() < b[property].toLowerCase()) ? -1 : (a[property].toLowerCase() > b[property].toLowerCase()) ? 1 : 0;
        return result * sortOrder;
    }
}